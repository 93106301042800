<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addDealerTeamMembersModal' hide-footer>
        <addDealerTeamMembers @closeAddDealerTeamMembers='toggleAddDealerTeamMembers()'
                              @addDealerTeamMembersSuccess='addDealerTeamMembersSuccess()'></addDealerTeamMembers>
      </b-modal>
      <b-modal ref='editDealerTeamMembersModal' hide-footer>
        <editDealerTeamMembers
          :editingDealerTeamMembers='currentDealerTeamMembers'
          @closeEditDealerTeamMembers='toggleEditDealerTeamMembers()'
          @editDealerTeamMembersSuccess='editDealerTeamMembersSuccess()'
        ></editDealerTeamMembers>
      </b-modal>
      <div class='row'>
        <h2>DealerTeamMembers</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddDealerTeamMembers()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true'
                   @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered/>
      </div>
    </div>
  </div>
</template>
<script>
import addDealerTeamMembers from '../../components/addDealerTeamMembers';
import editDealerTeamMembers from '../../components/editDealerTeamMembers';
import VueNotifications from 'vue-notifications';
import {Datatable} from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({types: toastTypes});


export default {
  data() {
    return {
      columns: [
        {
          label: 'Id',
          field: 'Id',
          sort: 'asc',
        },
        {
          label: 'Dealer',
          field: 'DealerName',
          sort: 'asc',
        },
        {
          label: 'FullName',
          field: 'FullName',
          sort: 'asc',
        },
        {
          label: 'Position',
          field: 'Position',
          sort: 'asc',
        },
        {
          label: 'ContactNumber',
          field: 'ContactNumber',
          sort: 'asc',
        },
        {
          label: 'EmailAddress',
          field: 'EmailAddress',
          sort: 'asc',
        },
        {
          label: 'Display Order',
          field: 'DisplayOrder',
          sort: 'asc',
        },
        {
          label: 'LastUpdated',
          field: 'LastUpdated',
          sort: 'asc',
        },

      ],
      items: [],
      dealers: [],
      addDealerTeamMembersOpen: false,
      editDealerTeamMembersOpen: false,
      currentDealerTeamMembers: {}
    };
  },
  components: {
    addDealerTeamMembers,
    editDealerTeamMembers,
    Datatable,
  },
  created: function () {
    this.getDealerTeamMembers();
    this.addDealerTeamMembersOpen = false;
    this.editDealerTeamMembersOpen = false;
    this.getDealers()
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    getDealers() {
      var payload = {
        success: response => {
          this.dealers = response.data;
        },
        error: error => {
          miniToastr['error'](error, 'Error', 1000, null)
        }
      }
      this.$store.dispatch('getDealers', payload);
    },
    isNull: function (obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    getDealerTeamMembers() {
      var payload = {
        success: response => {
          this.items = response.data;

          /*this.items.forEach(x => {
            if (this.dealers[x.DealerId]) {
              x.DealerName = this.dealers[x.DealerId].Slug;
            } else {
              x.DealerName = "Unset";
            }
          })
          console.log(this.items)*/
        },
        error: error => {
          miniToastr['error'](error, 'Error', 1000, null)
        }
      }
      this.$store.dispatch('getDealerTeamMembers', payload);
    },
    editItem(DealerTeamMembers) {
      this.toggleEditDealerTeamMembers();
      this.currentDealerTeamMembers = DealerTeamMembers;
    },
    toggleAddDealerTeamMembers() {
      if (this.addDealerTeamMembersOpen) {
        this.$refs.addDealerTeamMembersModal.hide()
      } else {
        this.$refs.addDealerTeamMembersModal.show()
      }
      this.addDealerTeamMembersOpen = !this.addDealerTeamMembersOpen;
    },
    addDealerTeamMembersSuccess() {
      this.toggleAddDealerTeamMembers();
      miniToastr['success']('DealerTeamMembers Added', 'Success', 1000, null);
      this.getDealerTeamMembers();
    },
    toggleEditDealerTeamMembers() {
      if (this.editDealerTeamMembersOpen) {
        this.$refs.editDealerTeamMembersModal.hide()
      } else {
        this.$refs.editDealerTeamMembersModal.show()
      }
      this.editDealerTeamMembersOpen = !this.editDealerTeamMembersOpen;
    },
    editDealerTeamMembersSuccess() {
      this.toggleEditDealerTeamMembers();
      miniToastr['success']('DealerTeamMembers Edited', 'Success', 1000, null);
      this.getDealerTeamMembers();
    }
  }
};
</script>
<style scoped>
.DealerTeamMembersThumbnail {
  height: 50px;
}
</style>
