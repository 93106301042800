<template>
  <div class='container'>
    <h2>Edit DealerTeamMembers</h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>

    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>

    <button @click='deleteDealerTeamMembers()' type='button' class='btn btn-danger'>Delete</button>

    <div class='row'>
      <div class='col-md-4'>
        <label class for='DealerId'>Dealer {{ editingDealerTeamMembers.DealerId }}</label>
        <!--   <input
             class='form-control'
             id='DealerId'
             name='DealerId'
             v-model='editingDealerTeamMembers.DealerId'
             placeholder=' '
             tabindex='0'
             type='text'
           >-->

        <select class='form-control' v-model='editingDealerTeamMembers.DealerId' style="display: inline!important;"
                name="DealerId" id="DealerId">
          <option v-for="(d) in dealers" :key="d.Id" :value="d.Id">{{ d.Description }}</option>
        </select>

      </div>
      <div class='col-md-4'>
        <label class for='FullName'>FullName</label>
        <input
          class='form-control'
          id='FullName'
          name='FullName'
          v-model='editingDealerTeamMembers.FullName'
          placeholder=' '
          tabindex='0'
          type='text'
        >

      </div>
      <div class='col-md-4'>
        <label class for='Position'>Position</label>
        <input
          class='form-control'
          id='Position'
          name='Position'
          v-model='editingDealerTeamMembers.Position'
          placeholder=' '
          tabindex='0'
          type='text'
        >

      </div>
      <div class='col-md-4'>
        <label class for='ContactNumber'>ContactNumber</label>
        <input
          class='form-control'
          id='ContactNumber'
          name='ContactNumber'
          v-model='editingDealerTeamMembers.ContactNumber'
          placeholder=' '
          tabindex='0'
          type='text'
        >

      </div>
      <div class='col-md-4'>
        <label class for='EmailAddress'>EmailAddress</label>
        <input
          class='form-control'
          id='EmailAddress'
          name='EmailAddress'
          v-model='editingDealerTeamMembers.EmailAddress'
          placeholder=' '
          tabindex='0'
          type='text'
        >

      </div>
      <div class='col-md-4'>
        <label class for='DisplayOrder'>DisplayOrder</label>
        <input
          class='form-control'
          id='DisplayOrder'
          name='DisplayOrder'
          v-model='editingDealerTeamMembers.DisplayOrder'
          placeholder=' '
          tabindex='0'
          type='text'
        >

      </div>
      <div class='col-md-4'>
        <label class for='LastUpdated'>LastUpdated</label>
        <input
          class='form-control'
          id='LastUpdated'
          name='LastUpdated'
          v-model='editingDealerTeamMembers.LastUpdated'
          placeholder=' '
          tabindex='0'
          type='text'
        >

      </div>
      <div class='col-md-4'>
        <input id="Atos" type="checkbox" autocomplete="off" class="custom-control-input" value="true">
        <label class for='LastUpdated'>Show on Corporate Sales</label>
        <input
          class='form-control'
          id='ShowOnCorporateSales'
          name='ShowOnCorporateSales'
          v-model='editingDealerTeamMembers.ShowOnCorporateSales'
          placeholder=' '
          tabindex='0'
          type='checkbox'
          style="display: inline!important; position: relative; opacity: 1; pointer-events: all"
        ></input>

      </div>
      <div class='col-md-4'>
        <label class for='LastUpdated'>Profile Picture</label>
        <br/>
        {{ tmpImageUrl }}
        <img :src="getImageUrl()" width="100px"/>
        <input
          @change="upload()"
          class='form-control'
          id='image'
          name='image'
          placeholder=' '
          tabindex='0'
          type='file'
        >
      </div>
    </div>
    <br/>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
  </div>

</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import miniToastr from "mini-toastr";
import { baseUrl } from "@/store/store";
import axios from "axios"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditDealerTeamMembers",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
      dealers: [],
      imageUrl: "",
      tmpImageUrl: null,
      baseUrl: "",
    };
  },
  components: {
    FileUploader,
  },
  created() {
    // this.imageUrl = baseUrl + "ProfilePicture/Image?id=";

    this.baseUrl = baseUrl;

    if (location.href.indexOf("localhost") > -1) {
      
      this.baseUrl = "https://api.stage.hyundai.co.za/api";
      // this.cdnUrl = "https://api.stage.hyundai.co.za/ImageStorage/";
    }else{
      this.baseUrl = "https://api.hyundai.co.za/api";
    }
    
    this.imageUrl = this.baseUrl + "/ProfilePicture/Image?id=";
    this.originalData = JSON.parse(
      JSON.stringify(this.editingDealerTeamMembers)
    );
    this.ImageBytes = this.editingDealerTeamMembers.Image;
    this.getDealers();
  },

  props: ["editingDealerTeamMembers"],

  methods: {

    getImageUrl(){
      if(this.imageUrl == null){
        return '';
      }
      if(!this.editingDealerTeamMembers){
        return '';
      }
      return this.imageUrl+this.editingDealerTeamMembers.Id
    },
    toggleView: function () {
      this.$emit("closeEditDealerTeamMembers");
    },
    getDealers() {
      var payload = {
        success: (response) => {
          this.dealers = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getDealers", payload);
    },
    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingDealerTeamMembers));
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("editDealerTeamMembersSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("editDealerTeamMembers", payload);
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deleteDealerTeamMembers: function () {
      var payload = {
        data: this.editingDealerTeamMembers,
        success: (response) => {
          this.$emit("editDealerTeamMembersSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteDealerTeamMembers", payload);
    },
    upload: function () {
      
      var vm = this;
      var data = this.editingDealerTeamMembers;
      var fileSelect = document.getElementById("image");
      var image = fileSelect.files[0];

      //check image width and height
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      var objectUrl = _URL.createObjectURL(image);
      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;

        window.URL.revokeObjectURL(objectUrl);

        if (width != 200 || height != 200) {
          miniToastr["error"](
            "Image must be 200px by 200px",
            "Error",
            3000,
            null
          );
          return;
        } else {
          if (location.href.indexOf("localhost") > -1) {
            this.baseUrl = "https://api.stage.hyundai.co.za/api/";
            // this.cdnUrl = "https://api.stage.hyundai.co.za/ImageStorage/";
          }else{
            this.baseUrl = "https://api.hyundai.co.za/api/";
          }
          var formData = new FormData();
          formData.append("image", image);
          axios
            .post(
              this.baseUrl + "ProfilePicture/Upload?id=" + data.Id,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              
              //this is realy stupid ffs, should probably use an evvent bus or something
              var tmp =
                this.baseUrl +
                "/ProfilePicture/Image?foo=" +
                Math.random() +
                "&id=";

                vm.imageUrl = '';
                setTimeout(() => {
                  vm.imageUrl = tmp;
                  
                 
                  vm.$forceUpdate();
                 
                }, 500);

              
            })
            .catch(() => {
              console.log("Failed to upload profile picture");
            });
        }
      };
      img.src = objectUrl;
    },
  },
};
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style>
